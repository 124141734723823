* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  touch-action: none !important;
}

.app-container {
  font-family: Poppins;
  background-image: url('./assets/images/Background.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
